import React, {Component} from 'react';
import {
    Switch,
    Route
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

// styles
import './styles/App.scss';

// pages
import Navigace from "./components/navigation/navigation";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import Story from "./pages/story/story";
import History from "./pages/history/history";
import Today from "./pages/today/today";
import Support from "./pages/support/support";
import Meantime from "./pages/meantime/meantime";

class App extends Component{

    constructor(props) {
        super(props);
        this.state = {
            lang: "CZ",
        }
    }

    //lang switch button handler
    handler = (val) => {
        if (val === "CZ") {
            this.setState({
                lang: "EN"
            })
        } else {
            this.setState({
                lang: "CZ"
            })
        }
    };

    //lang preference detection
    componentWillMount() {
        if (navigator.languages[0].includes("CZ") || navigator.languages[0].includes("SK")){
            this.setState({
                lang: "CZ"
            })
        } else {
            this.setState({
                lang: "CZ"
            })
        }
    }

    componentDidMount() {
        AOS.init();
    }


    render() {
        return (
            <div className="App">
                <Navigace language={this.state.lang}/>
                <Switch>
                    <Route exact path="/">
                        <Home language={this.state.lang}/>
                    </Route>
                    <Route path="/pribeh">
                        <Story language={this.state.lang}/>
                    </Route>
                    <Route path="/1949">
                        <History language={this.state.lang}/>
                    </Route>
                    <Route path="/mezi">
                        <Meantime language={this.state.lang}/>
                    </Route>
                    <Route path="/2020">
                        <Today language={this.state.lang}/>
                    </Route>
                    <Route path="/podporte">
                        <Support language={this.state.lang}/>
                    </Route>
                    <Route>
                        <Home language={this.state.lang}/>
                    </Route>
                </Switch>
                <Footer handler={this.handler} language={this.state.lang}/>
            </div>
        );
    }
}

export default App;
