import React, {Component} from "react";
import loadData from "../../data/data";

//svg
import {ReactComponent as Logo} from "../../images/logo.svg";
import {ReactComponent as Illustration} from "../../images/footer_illustration.svg";

import {Link, NavLink} from "react-router-dom";

class Footer extends Component{
    render() {
        const data = loadData.[this.props.language][0];
        return (
            <div className="footer">
                <div className="footer-left">
                    <div className="footer-logo">
                        Aero Minor Sport<br/>Le Mans Redux
                    </div>
                    <div className="footer-illustration">
                        <Illustration/>
                    </div>
                </div>
                <div className="footer-nav">
                    <ul>
                        <li>
                            <Link to="pribeh" title="Zapomenutý příběh československé   formule Aero Minor Sport">{data.links[1].link}</Link>
                        </li>
                        <li>
                            <Link to="1949" title="Závod 24h Le Mans rok 1949  a závodníci František Sutnar a Otto Krattner">{data.links[2].link}</Link>
                        </li>
                        <li>
                            <Link to="mezi" title="Aero Minor Sport a František Sutnar   s Ottou Krattnerem po závodu 25h Le Mans">{data.links[3].link}</Link>
                        </li>
                        <li>
                            <Link to="2020" title="Aero Minor Sport   formule se vrací na startovní čáru legendárního závodu 24h Le Mans">{data.links[4].link}</Link>
                        </li>
                        <li>
                            <Link to="podporte" title="Formuly Aero Minor Sport   podporují a chtějí ji dostat   na závod 24h Le Mans">{data.links[5].link}</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => this.props.handler(this.props.language)}>{this.props.language === "CZ" ? "English" : "Čeština"}</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-address">
                    Le Mans Redux z.s.<br/>
                    IČO: 07484852<br/>
                    Kostelní 36/36,<br/>
                    170 00 Praha<br/><br/>

                    <a href="mailto:info@aerominorsport.cz" title="Napište spolku Aero Minor Sport Le Mans Redux">info@aerominorsport.cz</a><br/>
                    <a href="tel:+420602303944" title="Zavolejte spolku Aero Minor Sport Le Mans Redux">+420 602 303 944</a>

                </div>
            </div>
        );
    }
}

export default Footer;