import React, {Component} from "react";

import Nav from "./nav";
import NavMobile from "./nav-mobile";

class Navigace extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: true,
            isTop: true,
            scrollPosition: 0,
            width: window.innerWidth,
            scale: 0.8
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener('resize', this.handleWindowSizeChange);
        if (window.innerWidth > 480){
            this.setState({scale: 2});
        } else {
            this.setState({scale: 1});
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
        if (window.innerWidth > 480){
            this.setState({scale: 2});
        } else {
            this.setState({scale: 0.8});
        }
    };

    handleScroll() {
        const {scrollPosition} = this.state;
        this.setState({
            isTop: document.body.getBoundingClientRect().top === 0
        });
        if (Math.abs(document.body.getBoundingClientRect().top - scrollPosition) < 60) {
            return;
        }
        this.setState({
            scrollPosition: document.body.getBoundingClientRect().top,
            show: document.body.getBoundingClientRect().top > scrollPosition
        });
    }

    render() {
        const {width} = this.state;
        const isMobile = width <= 768;

        if (isMobile) {
            return (
                <NavMobile
                    className={this.state.show ? (this.state.isTop ? "menu-default-top menu-default" : "menu-default") : "menu-default hidden"}
                    language={this.props.language} scale={this.state.scale}
                />
            );
        } else {
            return (
                <Nav
                    className={this.state.show ? (this.state.isTop ? "nav top nav-active" : "nav nav-active") : "nav hidden"}
                    language={this.props.language}
                />
            );
        }
    }
}

export default Navigace;