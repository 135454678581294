import React from "react";
import Fade from 'react-reveal/Fade';

function BlockImage(props) {
    return (
        <div className="block-image">
            <div>
                <Fade bottom duration={500} distance="100px">
                    <img src={props.img} alt={props.alt} title={props.title}/>
                </Fade>
            </div>
        </div>
    )
}

export default BlockImage;