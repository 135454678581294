import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {ParallaxProvider} from 'react-scroll-parallax';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-5ZW3EMRQK3'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <React.StrictMode>
        <ParallaxProvider>
            <Router>
                <App/>
            </Router>
        </ParallaxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
