import React from "react";
import {Link} from "react-router-dom";
import {Parallax} from 'react-scroll-parallax';


function BlockLink(props) {
    const className = "block-link " + props.className;
    return (
        <div className={className}>
            <Link to={props.to} title={props.title}>
                <Parallax className="block-link-illustration" x={[10, -10]}>
                    <img src={props.illustration} alt="Illustration"/>
                </Parallax>
                <div className="block-link-overlay">
                    <h2>{props.content}</h2>
                    <span className="block-link-button">{props.buttonText}</span>
                </div>
                <img src={props.img} alt={props.alt}/>
            </Link>
        </div>
    )
}

export default BlockLink;