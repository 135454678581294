import React, {Component} from "react";

//img
import {ReactComponent as Arrow} from "../../images/arrow-down.svg";
import Block0_Mobile from "../../images/page4/block0_mobile.png";
import Block0_Ipad from "../../images/page4/block0_ipad.png";
import Block0 from "../../images/page4/block0.png";
import Block2 from "../../images/page4/block2.png";
import Block4 from "../../images/page4/block4.png";

//external img
import Block18 from "../../images/page0/block14.png";

//blocks
import BlockLink from "../../components/blocks/block-link";
import BlockText from "../../components/blocks/block-text";
import BlockImageTitle from "../../components/blocks/block-image-title";


//json data load
import loadData from "../../data/data";
import BlockImage from "../../components/blocks/block-image";

class Today extends Component{

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const data = loadData.[this.props.language][5];
        return (
            <div className="page page4">
                <div className="block-default block0">
                    <h1>{data.blocks[0].content}</h1>
                    <div className="arrow-down">
                        <Arrow/>
                    </div>
                    <picture>
                        <source media="(min-width: 800px)" srcSet={Block0} />
                        <source media="(min-width: 501px)" srcSet={Block0_Ipad} />
                        <source media="(max-width: 500px)" srcSet={Block0_Mobile} />
                        <img src={Block0} alt="Le Mans 49 race"/>
                    </picture>
                </div>
                <div className="block-text">
                    <p>{data.blocks[1].content}</p><br/>
                    <p>{data.blocks[1].content2}</p>
                </div>
                <div className="block2">
                    <img src={Block2} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport"/>
                </div>
                <div className="block-story-text block3">
                    <p>{data.blocks[3].content}</p><br/>
                    <p>{data.blocks[3].content2}</p><br/>
                    <p>{data.blocks[3].content3}</p><br/>
                    <p>{data.blocks[3].content4}</p><br/>
                    <p>{data.blocks[3].content5}</p><br/>
                    <p>{data.blocks[3].content6}</p><br/>
                    <p>{data.blocks[3].content7}</p>
                </div>
                <BlockImageTitle className="block4" img={Block4} />
                <BlockLink to="/podporte" title="Formuli Aero Minor Sport   podporují a chtějí ji dostat   na závod 24h Le Mans" content={data.blocks[5].content} buttonText={data.blocks[5].buttonText} img={Block18}/>
            </div>
        )
    }
}

export default Today;
