import React from "react";

function BlockText(props) {
    return (
        <div className="block-text">
            <p>{props.text}</p>
        </div>
    )
}

export default BlockText;