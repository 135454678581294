import React from "react";
import {NavLink} from "react-router-dom";
import Logo from "../../images/logo.svg";
import loadData from "../../data/data";
import Arrow from "../../images/menuarr.svg"

function Nav(props) {
    const data = loadData.[props.language][0];
    return (
        <ul className={props.className}>
            <li>
                <NavLink to="/" activeClassName="active" title="Aero Minor Sport  československá legendární   formule závodu Le Mans" className="nav--logo"><h1>Aero Minor Sport<br/>Le Mans Redux</h1></NavLink>
            </li>
            <li className="nav-divider nav-item">
                <NavLink to="pribeh" activeClassName="active" title="Zapomenutý příběh československé   formule Aero Minor Sport">{data.links[1].link}</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="1949" activeClassName="active" title="Závod 24h Le Mans rok 1949  a závodníci František Sutnar a Otto Krattner">{data.links[2].link}</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="mezi" activeClassName="active" title="Aero Minor Sport a František Sutnar   s Ottou Krattnerem po závodu 25h Le Mans"><img src={Arrow} alt="arrow"/></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="2020" activeClassName="active" title="Aero Minor Sport   formule se vrací na startovní čáru legendárního závodu 24h Le Mans">{data.links[4].link}</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="podporte" className="nav-item-right" activeClassName="active" title="Formuli Aero Minor Sport   podporují a chtějí ji dostat   na závod 24h Le Mans">{data.links[5].link}</NavLink>
            </li>
        </ul>
    )
}

export default Nav;