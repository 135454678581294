import React, {Component} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { Jednoznakovky } from "../../jednoznakovky";

//img
import {ReactComponent as Arrow} from "../../images/arrow-down.svg";
import Block0 from "../../images/page2/block0.png";
import Block0_Ipad from "../../images/page2/block0_ipad.png";
import Block0_Mobile from "../../images/page2/block0_mobile.png";
import Block2 from "../../images/page2/block2.png";
import Block2_Illustration from "../../images/page0/block5_illustration.png";
import Block4 from "../../images/page2/block4.png";
import Block6 from "../../images/page2/block6.png";
import Block8 from "../../images/page2/block8.png";

//blocks
import BlockLink from "../../components/blocks/block-link";
import BlockText from "../../components/blocks/block-text";

//external img
import Block16 from "../../images/page0/block8.png";
import Block17 from "../../images/page0/block11.png";
import Block18 from "../../images/page0/block14.png";

//styling
import "./history.scss";

import loadData from "../../data/data";
import BlockImage from "../../components/blocks/block-image";

class History extends Component {

    constructor() {
        super();
        this.state = {
            position: 0
        }
    }

    componentWillMount() {
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 800px)": function () {


                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".block4",
                        //toggleActions: "play none none none",
                        //markers: "false",
                        pin: ".block4",
                        end: "+=500",
                        scrub: 1
                    },
                });

                timeline.to(".anim", {scale: 3})
                //.to(".block4", {translateY: "-1300", duration: 3})
                //.to(".block4", {opacity: 0.9, ease: "power1.inOut", duration: 1})

            }
        })
    }

    /*listenToScroll = () => {
        const top = this.viewElement.getBoundingClientRect().top;
        const mid = top * 12 - 1000;
        if (mid > 3000) {
            this.setState({position: 1});
        } else if (mid < 0) {
            this.setState({position: 2});
        } else {
            this.setState({position: 1.5});
        }
    };*/

    render() {
        const data = loadData.[this.props.language][3];
        let setRef = (el) => {
            this.viewElement = el;
        };
        const styles = {
            transform: 'scale(' + this.state.position + ')'
        };
        return (
            <div className="page page2">
                <div className="block-default block0">
                    <h1>{Jednoznakovky(data.blocks[0].content)}</h1>
                    <div className="arrow-down">
                        <Arrow/>
                    </div>
                    <picture>
                        <source media="(min-width: 800px)" srcSet={Block0}/>
                        <source media="(min-width: 501px)" srcSet={Block0_Ipad}/>
                        <source media="(max-width: 500px)" srcSet={Block0_Mobile}/>
                        <img src={Block0} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport"/>
                    </picture>
                </div>
                <div className="block-text">
                    <p>{data.blocks[1].content}</p><br/>
                    <p>{data.blocks[1].content2}</p>
                </div>
                <div className="block-image">
                    <img src={Block2} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport"/>
                </div>
                <div className="block-story-text block3">
                    <p>{data.blocks[3].content}</p><br/>
                    <p>{data.blocks[3].content2}</p><br/>
                    <p>{data.blocks[3].content3}</p><br/>
                    <p>{data.blocks[3].content4}</p><br/>
                    <p>{data.blocks[3].content5}</p><br/>
                    <p>{data.blocks[3].content6}</p>
                </div>
                <div className="block-story-img block4">
                    <p>{data.blocks[4].content}</p>
                    <img /* ref={setRef} style={styles}*/ className="anim" src={Block4} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport"/>
                </div>
                <div className="block-story-text block5">
                    <p>{data.blocks[5].content}</p><br/>
                    <p>{data.blocks[5].content2}</p><br/>
                    <p>{data.blocks[5].content3}</p><br/>
                    <p>{data.blocks[5].content4}</p>
                </div>
                <div className="block-story-img block6">
                    <div>
                        <img src={Block6} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport"/>
                        <p>{data.blocks[6].content}</p>
                    </div>
                </div>
                <div className="block-story-text block7">
                    <p>{data.blocks[7].content}</p><br/>
                    <p>{data.blocks[7].content2}</p><br/>
                    <p>{data.blocks[7].content3}</p><br/>
                    <p>{data.blocks[7].content4}</p><br/>
                    <p>{data.blocks[7].content5}</p><br/>
                    <p>{data.blocks[7].content6}</p>
                </div>
                <div className="block-story-img block8">
                    <div>
                        <img src={Block8} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport"/>
                        <p>{data.blocks[8].content}</p>
                    </div>
                </div>
                <div className="block-story-text block9">
                    <p>{data.blocks[9].content}</p><br/>
                    <p>{data.blocks[9].content2}</p>
                </div>
                <BlockLink to="/mezi" title="Aero Minor Sport a František Sutnar   s Ottou Krattnerem po závodu 25h Le Mans" content={data.blocks[10].content} buttonText={data.blocks[10].buttonText}
                           img={Block16}/>
                <BlockLink to="/2020" title="Aero Minor Sport   formule se vrací na startovní čáru legendárního závodu 24h Le Mans" content={data.blocks[11].content} buttonText={data.blocks[11].buttonText}
                           img={Block17}/>
                <BlockLink to="/podporte" title="Formuli Aero Minor Sport   podporují a chtějí ji dostat   na závod 24h Le Mans" content={data.blocks[12].content} buttonText={data.blocks[12].buttonText}
                           img={Block18}/>
            </div>
        );
    }
}

export default History;
