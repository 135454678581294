import React, {Component} from "react";

//img
import Nadace from "../../images/nadace.png"
import Bohematic from "../../images/bohematic.png"
import Sipral from "../../images/sipral.jpg"
import Mmcite from "../../images/mmcite.png"
import Dipozitiv from "../../images/dipozitiv.jpg"
//
import Block14 from "../../images/page0/block2.png";
import Block15 from "../../images/page0/block5.png";
import Block16 from "../../images/page0/block8.png";
import Block17 from "../../images/page0/block11.png";
import Block18 from "../../images/page0/block14.png";

//blocks
import BlockLink from "../../components/blocks/block-link";

import loadData from "../../data/data";


class Support extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const data = loadData.[this.props.language][6];
        return (
            <div className="page page5">
                <div className="block0">
                    <div className="block0--main">
                        <h1>S laskavou podporou Nadace ČEZ</h1>
                        <div className="block0--image">
                            <a href="https://www.nadacecez.cz/"><img src={Nadace} alt="Nadace ČEZ"/></a>
                        </div>
                    </div>
                </div>
                <div className="block1">
                    <div className="block1--other">
                        <h2>Partneři zapojení do tohoto projektu</h2>
                        <div className="block1--image">
                            <a href="https://bohematic.cz"><img className="bohematic" src={Bohematic} alt="Bohematic"/></a>
                            <a href="https://www.mmcite.com"><img src={Mmcite} alt="Mmcite"/></a>
                            <a href="https://www.sipral.cz/cz/home"><img src={Sipral} alt="Sipral"/></a>
                            <a href="https://dipozitiv.cz/" title="Designérské a grafické studio Dipozitiv"><img className="dipozitiv" src={Dipozitiv} alt="Studio Dipozitiv"/></a>
                        </div>
                    </div>
                </div>
                <BlockLink to="/pribeh" title="Zapomenutý příběh československé   formule Aero Minor Sport" content={data.blocks[1].content} buttonText={data.blocks[1].buttonText}
                           img={Block14}/>
                <BlockLink to="/1949" title="Závod 24h Le Mans rok 1949  a závodníci František Sutnar a Otto Krattner" content={data.blocks[2].content} buttonText={data.blocks[2].buttonText}
                           img={Block15}/>
                <BlockLink to="/mezi" title="Aero Minor Sport a František Sutnar   s Ottou Krattnerem po závodu 25h Le Mans" content={data.blocks[3].content} buttonText={data.blocks[3].buttonText}
                           img={Block16}/>
                <BlockLink to="/2020" title="Aero Minor Sport   formule se vrací na startovní čáru legendárního závodu 24h Le Mans" content={data.blocks[4].content} buttonText={data.blocks[4].buttonText}
                           img={Block17}/>
                <BlockLink to="/podporte" title="Formuli Aero Minor Sport   podporují a chtějí ji dostat   na závod 24h Le Mans" content={data.blocks[5].content} buttonText={data.blocks[5].buttonText}
                           img={Block18}/>
            </div>
        )
    }
}

export default Support;