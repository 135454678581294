import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import Logo from "../../images/logo.svg";
import Burger from 'react-css-burger';
import loadData from "../../data/data";
import Arrow from "../../images/menuarr.svg"

class NavMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {active: false};
    }

    render() {
        const data = loadData.[this.props.language][0];
        const scale = this.props.scale;
        return (
            <div className={this.state.active ? "nav-mobile nav-mobile-color" : "nav-mobile"}>
                <div className={this.props.className}>
                    <div className="text-logo">
                        <NavLink to="/" title="Aero Minor Sport  československá legendární   formule závodu Le Mans" activeClassName="active" onClick={() => this.setState({active: false})}><h1>Aero Minor Sport<br/>Le Mans Redux</h1></NavLink>
                    </div>
                    <Burger
                        onClick={() => this.setState({active: !this.state.active})}
                        active={this.state.active}
                        burger="slider"
                        marginTop="0"
                        marginLeft="0"
                        scale={scale}
                    />

                </div>
                <div className={this.state.active ? "menu-overlay" : "menu-overlay menu-overlay-hidden"}>
                    <ul>
                        <li className="nav-divider nav-item">
                            <NavLink to="pribeh" activeClassName="active" title="Zapomenutý příběh československé   formule Aero Minor Sport"
                                     onClick={() => this.setState({active: false})}>{data.links[1].link}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="1949" activeClassName="active" title="Závod 24h Le Mans rok 1949  a závodníci František Sutnar a Otto Krattner"
                                     onClick={() => this.setState({active: false})}>{data.links[2].link}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="mezi" activeClassName="active" title="Aero Minor Sport a František Sutnar   s Ottou Krattnerem po závodu 25h Le Mans"
                                     onClick={() => this.setState({active: false})}><img src={Arrow} alt="arrow"/></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="2020" activeClassName="active" title="Aero Minor Sport   formule se vrací na startovní čáru legendárního závodu 24h Le Mans"
                                     onClick={() => this.setState({active: false})}>{data.links[4].link}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="podporte" className="nav-item-right" activeClassName="active" title="Formuly Aero Minor Sport   podporují a chtějí ji dostat   na závod 24h Le Mans"
                                     onClick={() => this.setState({active: false})}>{data.links[5].link}</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default NavMobile;