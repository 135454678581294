import React from "react";

function BlockStory(props) {
    return (
        <div className="block-story-text">
            <h4>{props.title}</h4>
            <p>{props.text}</p><br/>
            <p>{props.text2}</p><br/>
            <p>{props.text3}</p>
        </div>
    )
}

export default BlockStory;