import React from "react";
import BlockImage from "./block-image";

function BlockImageTitle(props) {
return (
    <div className="block-story-img">
        <div className="block-story-img-wrapper">
            <img src={props.img} alt="Československý závodní automobil Aero Minor Sport" title="Legendární závodní automobil Aero Minor Sport" />
            <p>{props.content}</p>
        </div>
    </div>
)
}

export default BlockImageTitle;